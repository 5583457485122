<template>
    <div v-if="images && Object.keys(images).length" class="block examples-gallery">
        <div class="heading mb-3">{{$t('examples')}}</div>
        <div>
            <figure class="image is-inline-block ml-1 fa-border is-64x64"
                    @click="switchGalleryView(true, imageIndex)"
                    v-for="(image, imageIndex) in Object.values(images)"
                    :key="image.uuid">
                <b-image :src="image.preview_url" ratio="3by2" :responsive="false" class="is-clickable"></b-image>
            </figure>
        </div>

        <b-carousel
            v-model="currentImageIndex"
            v-if="asGallery"
            :autoplay="false"
            :repeat="false"
            :arrow-hover="false"
            :indicator-inside="false"
            :overlay="true"
            icon-size="is-medium">

            <b-carousel-item v-for="image in images" :key="image.uuid">
                <b-image :draggable="false"
                         class="has-text-centered is-fullheight"
                         :responsive="false"
                         :src="image.original_url"
                         :title="image.name"></b-image>
            </b-carousel-item>

            <span @click="switchGalleryView(false)" class="modal-close is-large has-background-grey-light"></span>

        </b-carousel>
    </div>
</template>

<script>
export default {
    name: "ListItemExamplesGallery",
    props: ['images'],
    data(){
        return {
            asGallery: false,
            currentImageIndex: 0,
        };
    },
    methods: {
        switchGalleryView(asGallery, imageIndex = 0){
            this.asGallery = asGallery;
            this.currentImageIndex = imageIndex;
            if (asGallery) {
                return document.documentElement.classList.add('is-clipped');
            } else {
                return document.documentElement.classList.remove('is-clipped');
            }
        },
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        .carousel-item{
            height: 100%;
        }
        .is-fullheight{
            height: 100%;
            img {
                height: 100% !important;
            }
        }
    }
</style>