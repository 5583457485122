<template>
    <div class="mb-3">
        <b-field>
            <b-select v-model="targetArtisticProfileId" placeholder="" size="is-small">
                <option :value="null">{{$t('all projects')}}</option>
                <option v-for="artisticProfile in artisticProfiles"
                        :value="artisticProfile.id"
                        :key="'artistic-profile-' + artisticProfile.id">
                    {{artisticProfile.title}}
                </option>
            </b-select>
        </b-field>
    </div>
</template>

<script>
    import {GET_ARTISTIC_PROFILES} from "@/store/modules/artisticProfiles/actions-types";
    import {mapActions} from "vuex";
    import {
        GET_RELEVANT_VACANCIES,
        GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE
    } from "@/store/modules/relevantVacancies/action-types";
    import {
        SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION,
        SET_FILTER_TARGET_ARTISTIC_PROFILE_ID
    } from "@/store/modules/relevantVacancies/mutation-types";

    export default {
        name: "ArtisticProfileRelevantVacanciesListFilter",
        methods: {
            ...mapActions('artisticProfiles', [
                GET_ARTISTIC_PROFILES,
            ]),
            ...mapActions('relevantVacancies', [
                GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE,
                GET_RELEVANT_VACANCIES
            ]),
        },
        computed: {
            artisticProfiles(){
                return this.$store.state.artisticProfiles.profiles;
            },
            targetArtisticProfileId: {
                set(value){
                    this.$store.commit('relevantVacancies/' + SET_FILTER_TARGET_ARTISTIC_PROFILE_ID, value);
                },
                get(){
                    return this.$store.state.relevantVacancies.filterTargetArtisticProfileId;
                }
            },
        },
        watch:{
            targetArtisticProfileId(to, from){
              if ( to!== from ){
                this.$Progress.start();
                if (this.targetArtisticProfileId){
                  this[GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE]({
                      artisticProfileId:this.targetArtisticProfileId,
                  }).then(() => {
                    this.$Progress.finish();
                  });
                } else {
                  this[GET_RELEVANT_VACANCIES]().then(() => {
                    this.$store.commit('relevantVacancies/' + SET_ARTISTIC_PROFILE_RELEVANT_VACANCIES_PAGINATION, {});
                    this.$Progress.finish();
                  });
                }
              }
            }
        },
        mounted() {
            // this[GET_ARTISTIC_PROFILES]();
        },
        destroyed() {
            this.$store.commit('relevantVacancies/' + SET_FILTER_TARGET_ARTISTIC_PROFILE_ID, null);

        }
    };
</script>

<style scoped>

</style>