<template>
    <div class="card mb-4">

        <header class="card-header">

            <!--CITY / ABROAD FACTOR-->
            <div v-if="vacancy.city_id && !vacancy.abroad_contract" class="card-header-title is-size-7">
                <b-icon icon="map-marker-alt"></b-icon> {{ vacancy.city.name}}
            </div>
            <div v-if="vacancy.abroad_contract" class="card-header-title is-size-7">
                <b-icon icon="plane-departure" class="mr-1"></b-icon> {{$t('is abroad contract') }}
            </div>

            <!-- Open chat -->
            <span @click="chatActive = !chatActive"
                  v-if="vacancy.conversation || (application && types.application_status_accepted === application.status)"
                  class="card-header-icon"
                  :class="chatActive ? 'has-text-primary' : ''">
                <b-icon icon="envelope"></b-icon>
                <b-tag type="is-danger"
                       rounded
                       v-if="application && application.conversation_unread_messages">
                    {{application.conversation_unread_messages}}
                </b-tag>
            </span>

            <!--CREATED AT-->
            <div class="card-header-icon has-text-grey is-size-7">
                <b-icon icon="history"></b-icon> {{vacancy.created_at | dateFromNow}}
            </div>
            <!--TITLE-->
<!--            <p class="card-header-title">{{compiledVacancyTitle}}</p>-->
        </header>
        <div class="card-content px-2">
            <div class="vacancy-details" v-if="!chatActive">
                <!-- STATUSES TAGS -->
                <div class="block" v-if="!applicationStatusIs(types.application_status_accepted) && (vacancy.closed || existApplication)">
                    <b-taglist>
                        <!--APPLICATION STATUS-->
                        <b-tag v-if="existApplication" :type="application && applicationStatusIs(types.application_status_rejected ) ? 'is-danger' : 'is-success'">
                            {{applicationStatusIs(types.application_status_rejected) ? $t('application rejected') : $t('application sent')}}
                        </b-tag>

                        <!--IS CLOSED-->
                        <b-tag type="is-danger" v-if="vacancy.closed">{{$t('vacancy is closed')}}</b-tag>
                    </b-taglist>
                </div>

                <!--ORGANIZER CONTACTS, IF APPLICATIONS ACCEPTED-->
                <article class="message is-primary"  v-if="existApplication && types.application_status_accepted === application.status">
                    <div class="message-header">
                        <p>
                            {{$t('application accepted')}}
                        </p>
                    </div>
                    <div class="message-body px-2">
                        <div class="content">
                            {{$t('Your application has been accepted')}}<br>
                        </div>
                        <p class="content">
                            <strong>{{ vacancy.user.name}}</strong><br>
                            <strong> <a :href="`mailto:${vacancy.user.email}`">{{ vacancy.user.email }}</a></strong><br>
                        </p>
                        <p class="content">
                            <b-button type="is-secondary" @click="chatActive = !chatActive" size="is-small"><b-icon class="mr-1" icon="envelope"></b-icon> {{$t('send message')}} </b-button>
                        </p>
                    </div>
                </article>

                <!--FEE-->
                <div class="block">
                    <b-icon icon="coins" class="mr-1"></b-icon>
                    <span v-if="types.fee_type_agreed === vacancy.fee_type">{{ $t(vacancy.fee_type)}}</span>
                    <span v-else>{{ vacancy.fee_amount}} {{vacancy.fee_currency}}</span>
                </div>

                <!--LOCATION-->
                <div v-if="vacancy.location" CLASS="block">
                    <b-icon icon="map-marker-alt"></b-icon> {{ vacancy.location}}
                </div>

                <!--DATE AND TIME-->
                <div v-if="vacancy.date || vacancy.time" class="block">
                    <b-icon icon="calendar-alt" class="mr-1"></b-icon> <span v-if="vacancy.date">{{vacancy.date}}</span><!-- <span v-if="vacancy.time">time:{{vacancy.time}}</span>-->
                </div>

                <!--REQUIREMENTS-->
                <div class="block">
                    <div class="heading mb-3">{{ $t('requirements') }}</div>
                    <list-item-requirements v-if="vacancy.requirements" :vacancy="vacancy"/>
                </div>

                <!--EXAMPLES GALLERY-->

                <list-item-examples-gallery :images="vacancy.images" :vacancy-id="vacancy.id"/>

                <!--EXAMPLE LINKS-->
                <div class="block" v-if="vacancy.links.length">
                    <div class="heading mb-3">{{$t('links')}}</div>
                    <ul>
                        <li class="mb-2" v-for="link in vacancy.links" :key="'vacancy-' + vacancy.id + '-link-' + link.id">
                            <a :href="link.href" target="_blank">{{link.text}}</a>
                        </li>
                    </ul>
                </div>

                <!--INSTRUMENTS-->
                <div class="block" v-if="vacancy.instruments.length">
                    <div class="heading mb-3">{{$t('instruments')}}</div>
                    <b-taglist class="is-small">
                        <b-tag v-for="instrument in vacancy.instruments"
                               :key="'vacancy-' + vacancy.id + '-instrument-' + instrument.id">
                            {{$t(instrument.name)}}
                        </b-tag>
                    </b-taglist>
                </div>

                <!-- STYLISTIC OPTIONS-->
                <div class="block" v-if="vacancy.stylistic_options.length">
                    <div class="heading mb-3">{{$t('styles')}}</div>
                    <b-taglist>
                        <b-tag v-for="stylisticOption in vacancy.stylistic_options"
                               :key="'vacancy-' + vacancy.id + '-option-' + stylisticOption.id">
                            {{$t(stylisticOption.name)}}
                        </b-tag>
                    </b-taglist>
                </div>
                <!-- FEATURES -->
                <div class="block" v-if="vacancy.features && vacancy.features.length">
                    <div class="heading mb-3">{{$t('features')}}</div>
                    <b-taglist>
                        <b-tag v-for="feature in vacancy.features"
                               :key="'vacancy-' + vacancy.id + '-feature-' + feature.id">
                            {{$t(feature.type)}}
                        </b-tag>
                    </b-taglist>
                </div>
            </div>
            <keep-alive >
                <chat-thread v-if="chatActive" :application="application"/>
            </keep-alive>

            <!--VACANCY DETAILS BUTTON-->
<!--            <b-button @click="showDetails">{{ $t('details')}}</b-button>-->
        </div>

        <footer class="card-footer">

            <relevant-vacancy-application-request :vacancy="vacancy" />

            <a v-if="vacancy.closed || application"
                      class="card-footer-item"
                      @click.prevent="archiveApplicationForArtisticProfile"
                      :loading="formBusy">
                {{$t('hide')}}
            </a>
        </footer>
    </div>
</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {mapActions, mapMutations} from "vuex";
    import {
        REMOVE_RELEVANT_VACANCY_FROM_LIST,
        SET_VIEWABLE_RELEVANT_VACANCY
    } from "@/store/modules/relevantVacancies/mutation-types";
    import {
        ARCHIVE_ARTISTIC_APPLICATION,
        GET_ARTISTIC_APPLICATIONS
    } from "@/store/modules/artisticApplications/actions-types";
    import RelevantVacancyApplicationRequest from "./ApplicationRequest";
    import imgUrlGenerator from "@/mixins/imgUrlGenerator";
    import ListItemRequirements from "@/components/Artist/RelevantVacancies/ListItemRequirements";
    import confirmationMessageMixin from "@/mixins/confirmationMessageMixin";
    import ChatThread from "@/components/Chat/ChatThread";
    import ListItemExamplesGallery from "@/components/Artist/RelevantVacancies/ListItemExamplesGallery";

    export default {
        components: {ChatThread, ListItemRequirements, RelevantVacancyApplicationRequest, ListItemExamplesGallery},
        props: {
            vacancy: Object,
            index: Number,
        },
        data: () => ({
            feeAmount: null,
            feeCurrency: 'UAH',
            comment: null,
            showApplicationRequestForm: false,
            feeAmountRequired: false,
            showDetails: false,
            chatActive: false,
        }),
        methods: {
            ...mapMutations('relevantVacancies',[
                SET_VIEWABLE_RELEVANT_VACANCY,
                REMOVE_RELEVANT_VACANCY_FROM_LIST,
            ]),
            ...mapActions('artisticApplications', [
                GET_ARTISTIC_APPLICATIONS,
                ARCHIVE_ARTISTIC_APPLICATION,
            ]),
            async archiveApplicationForArtisticProfile(){
                const {result} = await this.$_p_confirmAction(this.$t('Hide the application?'));
                if (result){
                    this[ARCHIVE_ARTISTIC_APPLICATION](this.application.id)
                        .then(() => {
                            this[REMOVE_RELEVANT_VACANCY_FROM_LIST](this.index);
                        });
                }
            },
            applicationStatusIs(status){
                return this.existApplication && this.application.status === status;
            }
        },
        computed: {
            types(){
                return this.$store.state.base.applicationTypes;
            },
            artisticApplications(){
                return this.$store.state.artisticApplications.applications;
            },
            dataForApplicationCreateReady(){
                return this.vacancy.fee_amount_by_request ? !!this.feeAmount : true;
            },
            existApplication(){
                return !! this.application;
            },
            application(){
                return this.artisticApplications ? this.artisticApplications.find(application => {
                    return (application.vacancy_id === this.vacancy.id) && (application.artistic_profile.id === this.vacancy.target_artistic_profile_id);
                }) : null;
            },
            compiledVacancyTitle(){
                return this.$t(this.vacancy.artistic_profile_type.name);
            },
            formattedFee(){
                let fee = '';
                if(this.vacancy.fee_type === 'fixed'){
                    if ('USD' === this.vacancy.fee_currency){
                        fee = '$' + this.vacancy.fee_amount;
                    } else if ('UAH' === this.vacancy.fee_currency){
                        fee =  this.vacancy.fee_amount + ' грн.';
                    } else {
                        fee =  this.vacancy.fee_currency + this.vacancy.fee_amount;
                    }
                } else {
                    fee = this.$t('agreed budget');
                }
                return fee;
            }
        },
        mixins: [formHelperMixin, imgUrlGenerator, confirmationMessageMixin],
        name: "ArtisticProfileRelevantVacanciesListItem"
    };
</script>

<style scoped lang="scss">
    .examples-gallery {
        figure {
            overflow: hidden;
        }
    }
</style>