<template>
    <div class="relevant-vacancy-requirements">
        <div class="content" >
            {{ expanded ? vacancy.requirements : $options.filters.truncate(vacancy.requirements, length)}}
            <div class="has-text-centered" v-if="vacancy.requirements.length > length">
                <b-button type="is-text" href="#" size="is-small" @click="expanded = !expanded">{{ expanded ? $t('Show less') : $t('show more') }}</b-button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "ListItemRequirements",
    props: {
        vacancy: {
            type: Object,
        },
    },
    data(){
        return {
            expanded: false,
            length: 180,
        };
    },
};
</script>

<style scoped>
    .relevant-vacancy-requirements {
        white-space: pre-line;
    }
</style>