<template>
    <div class="artistic-dashboard">
        <div class="columns">

            <div class="column">
                <artist-dashboard-navigation />

                <artistic-profile-relevant-vacancies-list-filter />
            </div>
        </div>

        <artistic-profile-relevant-vacancies-list />

        <laravel-pagination :pagination-meta="paginationMeta" @change="getForTargetArtisticProfile" />

    </div>
</template>

<script>
    import {GET_ARTISTIC_PROFILES} from "@/store/modules/artisticProfiles/actions-types";
    import ArtisticProfileRelevantVacanciesList from "@/components/Artist/RelevantVacancies/List";
    import {GET_ARTISTIC_APPLICATIONS} from "@/store/modules/artisticApplications/actions-types";
    import {
        GET_RELEVANT_VACANCIES,
        GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE
    } from "@/store/modules/relevantVacancies/action-types";
    import {mapActions} from "vuex";
    import ArtisticProfileRelevantVacanciesListFilter from "@/components/Artist/RelevantVacancies/ListFilter";
    import ArtistDashboardNavigation from "@/components/Artist/DashboardNavigation";
    import LaravelPagination from "@/components/LaravelPagination";

export default {
    name: "ArtisticDashboard",
    title(){ return this.$t('Artistic dashboard');},
    data: () => ({
        activeTab: 0,
    }),
    methods: {
        ...mapActions('relevantVacancies',[
            GET_RELEVANT_VACANCIES,
            GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE,
        ]),
        ...mapActions('artisticApplications', [
            GET_ARTISTIC_APPLICATIONS,
        ]),
        ...mapActions('artisticProfiles',[
            GET_ARTISTIC_PROFILES,
        ]),
        getForTargetArtisticProfile(page = 1){
            this.$Progress.start();
            this[GET_RELEVANT_VACANCIES_FOR_ARTISTIC_PROFILE]({
                artisticProfileId:this.targetArtisticProfileId,
                page
            }).then(() => {
                this.$Progress.finish();
            }).catch(() => {
                this.$Progress.fail();
            });
        },
    },
    computed: {
        paginationMeta(){
            return this.$store.state.relevantVacancies.artisticProfileRelevantVacanciesPagination;
        },
        targetArtisticProfileId() {
            return this.$store.state.relevantVacancies.filterTargetArtisticProfileId;
        },
    },
    created() {
      this.$Progress.start();
      this[GET_ARTISTIC_APPLICATIONS]();
      this[GET_RELEVANT_VACANCIES]().then(() => {
          this.$Progress.finish();
      });
      this[GET_ARTISTIC_PROFILES]({page: 'all'});
    },
    components: {
            ArtistDashboardNavigation,
            ArtisticProfileRelevantVacanciesList,
            ArtisticProfileRelevantVacanciesListFilter,
            LaravelPagination,
        },
    };
</script>

<style scoped>

</style>