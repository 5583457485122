<template>
    <div class="container">

        <div class="has-text-centered  mt-7" v-if="!relevantVacancies || !relevantVacancies.length">
            <h4 class="title is-4 has-text-grey-dark">{{$t("There are no suitable vacancies yet, but don't worry! We will send you a notification as soon as something appears")}}</h4>
        </div>

        <relevant-vacancies-list-item
            v-for="(vacancy, index) in relevantVacancies"
            :vacancy="vacancy"
            :index="index"
            :key="'vacancy-' + vacancy.id + '-for-profile-' + vacancy.target_artistic_profile_id"/>

        <artistic-profile-relevant-vacancies-list-item-details />

    </div>
</template>

<script>
    import RelevantVacanciesListItem from "./ListItem";
    import {mapState} from "vuex";
    import ArtisticProfileRelevantVacanciesListItemDetails from "./Details";

    export default {
        components: {
            ArtisticProfileRelevantVacanciesListItemDetails,
            RelevantVacanciesListItem},
        data: function () {
            return {};
        },
        computed: {
            ...mapState({
                filterBy: state => state.artisticProfileRelevantVacancies.filterBy,
                relevantVacancies: state => state.relevantVacancies.vacancies,
            }),
        },

        name: "ArtisticProfileRelevantVacanciesList"
    };
</script>

<style scoped>

</style>