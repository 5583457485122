<template>
    <div class="buttons">
        <b-button tag="router-link"
                  size="is-small"
                  :to="{name: 'artisticDashboard'}"
                  :type="{'is-primary': routeNameIs('artisticDashboard')}"> {{$t('Vacancies')}}</b-button>
        <b-button tag="router-link"
                  size="is-small"
                  :type="{'is-primary': routeNameIs('artisticProfiles')}"
                  :to="{name: 'artisticProfiles'}">
            {{$t(`Your projects`)}} <b-tag rounded class="ml-1">{{artisticProfilesCount}}</b-tag>
        </b-button>
    </div>
</template>

<script>
import {GET_ARTISTIC_PROFILES_COUNT} from "@/store/modules/artisticProfiles/actions-types";
import {mapActions} from "vuex";

export default {
    name: "ArtistDashboardNavigation",
    methods: {
        ...mapActions('artisticProfiles',[
            GET_ARTISTIC_PROFILES_COUNT,
        ]),
        routeNameIs(routeName){
            return this.$route.name === routeName;
        },
    },
    computed: {
        artisticProfilesCount(){
            return this.$store.state.artisticProfiles.profilesCount;
        }
    },
    created() {
        this[GET_ARTISTIC_PROFILES_COUNT]();
    },
};
</script>

<style scoped>

</style>